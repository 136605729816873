import {h} from 'preact';
import register from 'preact-custom-element';
import { TestimonialDataLoader, type TestimonialInitialData } from "@/components/TestimonialRebrandDataLoader"

const RenderTestimonialCarousel = ({ rightArrowIcon, leftArrowIcon, testimonialData, testimonialImages }: TestimonialInitialData) => {
    return h(TestimonialDataLoader, { rightArrowIcon, leftArrowIcon, testimonialData, testimonialImages })
}


register(
    RenderTestimonialCarousel,
    'testimonial-carousel-rebrand',
    ['testimonial-data', 'right-arrow-icon', 'left-arrow-icon', 'testimonial-images']
);