import { useEffect, useState } from 'preact/hooks';
import { TestimonialCarouselRebrand } from '@/components/TestimonialCarouselRebrand';
import { Testimonial } from '@/components/TestimonialCard';
import { defaultData } from '@/components/data/TestimonialCarouselRebrand';

import { logError } from '@/utilities/log';

export type TestimonialInitialData = {
    testimonialData: string,
    testimonialImages: string,
    leftArrowIcon: string
    rightArrowIcon: string
}


export const TestimonialDataLoader = ({
    testimonialData,
    leftArrowIcon,
    rightArrowIcon,
    testimonialImages
}: TestimonialInitialData) => {
    const [testimonialArr, setTestimonialArr] = useState<Testimonial[]>([]);
    const [leftArrowLink, setLeftArrowLink] = useState("");
    const [rightArrowLink, setRightArrowLink] = useState("");

    const processData = (items: Testimonial[], images: string[]) => {
        return items.map((item, i) => ({...item, image: images[i] || ''}))
    }

    useEffect(() => {
            try {
                if(testimonialData) {
                    const parsedItems = JSON.parse(testimonialData) as Testimonial[];
                    const parsedImages = JSON.parse(testimonialImages) as string[];
                    const processedItems = processData(parsedItems, parsedImages);
                    setTestimonialArr(processedItems);
                } else {
                    setTestimonialArr(defaultData);
                }

                if(leftArrowIcon) setLeftArrowLink(leftArrowIcon)
                if(rightArrowIcon) setRightArrowLink((rightArrowIcon))
            } catch (error) {
                // if there's an error, testimonalData will remain an empty array
                logError(error)
            }
    }, [
        testimonialData,
        testimonialImages,
        leftArrowIcon,
        rightArrowIcon
    ]);

    return (
        <>
            <TestimonialCarouselRebrand
                testimonialData={testimonialArr}
                rightArrowIcon={rightArrowLink}
                leftArrowIcon={leftArrowLink}
            />
        </>
    )
}
