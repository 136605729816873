import Slider, {type Settings} from '@ant-design/react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {CustomCarouselArrow} from '@/components/CustomCarouselArrow';

import {TestimonialCard, Testimonial} from '@/components/TestimonialCard';

export type TestimonialCarouselRebrandProps = {
  rightArrowIcon?: string;
  leftArrowIcon?: string;
  testimonialData: Testimonial[];
};

const baseSettings: Settings = {
  className: 'slider variable-width overflow-x-hidden h-full',
  infinite: true,
  autoplay: true,
  variableWidth: true,
  autoplaySpeed: 3000,
  rows: 1,
  cssEase: 'linear',
  centerPadding: '16px',
  centerMode: true,
  swipeToSlide: true,
  adaptiveHeight: true,
};

export const TestimonialCarouselRebrand = ({
  rightArrowIcon,
  leftArrowIcon,
  testimonialData,
}: TestimonialCarouselRebrandProps) => {
  if (rightArrowIcon && leftArrowIcon) {
    baseSettings.nextArrow = (
      <CustomCarouselArrow
        link={rightArrowIcon}
        classNames="right-2 opacity-80 hover:opacity-100"
      />
    );
    baseSettings.prevArrow = (
      <CustomCarouselArrow
        link={leftArrowIcon}
        classNames="left-2 opacity-80 hover:opacity-100"
      />
    );
    baseSettings.arrows = true;
  }

  return (
    <Slider {...baseSettings}>
      {testimonialData.map((item) => {
        return <TestimonialCard key={item.name} {...item} />;
      })}
    </Slider>
  );
};
