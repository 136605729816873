import StarRating from '@/components/baseComponents/StarRating';

export type Testimonial = {
  image?: string;
  stars: string;
  text: string;
  name: string;
  color: string;
};

const getColor = (color: string) => {
  const obj: Record<string, string> = {
    'breeze-blue': 'bg-breeze-blue',
    'deep-ocean-blue': 'bg-deep-ocean-blue',
    'light-breeze': 'bg-light-breeze',
    'fresh-scent': 'bg-fresh-scent',
    'wild-blossoms': 'bg-wild-blossoms',
    sport: 'bg-sport',
    sunrise: 'bg-sunrise',
  };

  return obj[color] || 'bg-breeze-blue';
};

export const TestimonialCard = ({
  stars,
  text,
  name,
  color,
  image,
}: Testimonial) => (
  <div
    // eslint-disable-next-line tailwindcss/no-custom-classname
    className={`max-w-84 mr-8 flex h-160 flex-col justify-items-center gap-y-4 rounded-3xl ${getColor(color)} text-crisp-white p-6 text-center text-lg font-medium`}
  >
    {image ? (
      <img
        loading="lazy"
        className="mb-2 h-72 w-96 grow-0 rounded-3xl object-cover object-center"
        width="276"
        height="282"
        src={image}
        alt={text}
      />
    ) : null}
    <div className="grow-0 self-center">
      <StarRating rating={parseInt(stars) || 5} />
    </div>
    <p className="flex flex-col gap-y-4">
      <span>{truncateText(text, 200)}</span>
      <span>{name}</span>
    </p>
  </div>
);

/**
 * Truncates a given text to a specified length.
 *
 * @param text - The text to be truncated.
 * @param length - The maximum length of the truncated text.
 * @returns The truncated text.
 */
function truncateText(text: string, length: number) {
  return text.slice(0, length) + (text.length > length ? '...' : '');
}
