
// Define a TypeScript interface for the component props
type StarRatingProps = {
  rating?: number;
  maxStars?: number;
}

const StarRating = ({ rating = 0, maxStars = 5 }: StarRatingProps) => {
  const filledStars = rating;
  const grayStars = maxStars - rating;

  const renderStar = (colorClass: string) => (
    <svg xmlns="http://www.w3.org/2000/svg" className={`size-6 ${colorClass}`} fill="currentColor" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
      <path d="M12 2l2.39 4.842 5.34.777-3.86 3.763.91 5.31-4.77-2.506-4.77 2.506.91-5.31-3.86-3.763 5.34-.777L12 2z"/>
    </svg>
  );

  return (
    <div className="flex items-center">
      {Array.from({ length: filledStars }, () => renderStar('text-sunshine'))}
      {Array.from({ length: grayStars }, () => renderStar('text-neutral-gray'))}
    </div>
  );
};

export default StarRating;
